import { window } from "global";
import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { RiotAssetsProvider } from "@outplayed/riot-assets";
import { LightModeProvider } from "components/LightModeContext";
import AppDataInitializer from "./AppDataInitializer";
import Routes from "router.js";
import Responsive from "components/Responsive";
import { useQuery } from "@apollo/client";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { removeCookie } from "@ugg/shared/utils/cookies";
import { AdblockDetector } from "adblock-detector";
import { UGGAssetsProvider } from "@ugg/shared/components/UGGAssetsProvider";
import { RampContainer } from "components/Ads/RampContainer";

// if (window) {
//   document.cookie = "ugg_lang=en_US; path=/";
//   document.cookie = "ugg_lang=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/";
// }

const AdblockDetectorContainer = () => {
  const [adblock, setAdblock] = useGlobal("adblock");
  const [isDetectingAdblock, setDetectingAdblock] = useGlobal("isDetectingAdblock");

  useEffect(() => {
    if (window) {
      const adbDetector = new AdblockDetector();
      const userHasAdblock = adbDetector.detect();
      setDetectingAdblock(false);
      setAdblock(userHasAdblock);
    }
  }, []);

  return null;
};

const UserAuth = (props) => {
  const [authToken] = useGlobal("authToken");
  const [loggingIn, setLoggingIn] = useGlobal("loggingIn");
  const { loading, data, error } = useQuery(GET_USER_STATE, { skip: !authToken });
  useEffect(() => {
    if (error) {
      console.log(error);
      // If invalid authToken is saved, remove from cache
      // if (error.graphQLErrors[0].message === "unauthorized") {
      if (window && authToken) {
        removeCookie("authToken");
        // sessionStorage.removeItem("authToken");
        // localStorage.removeItem("authToken");
        // window.location.reload();
      }
      // }
    }
    if (!loading) {
      setLoggingIn(false);
    }
  }, [loading]);

  return null;
};

const WebPSupport = () => {
  const [isWebP, setIsWebP] = useGlobal("isWebP");

  useEffect(() => {
    (async () => {
      try {
        if (!window) return true;
        if (isWebP !== null && isWebP !== undefined) {
          return isWebP;
        }
        // If the browser doesn't has the method createImageBitmap, you can't display webp format
        if (!self.createImageBitmap) {
          setIsWebP(false);
          return false;
        }

        // Base64 representation of a white point image
        const webpData = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";

        // Retrieve the Image in Blob Format
        const blob = await fetch(webpData).then((r) => r.blob());

        // If the createImageBitmap method succeeds, return true, otherwise false
        const webP = createImageBitmap(blob).then(
          () => true,
          () => false,
        );

        if (await webP) {
          setIsWebP(true);
        } else {
          setIsWebP(false);
        }
      } catch (error) {}
    })();
  }, []);

  return null;
};

const App = (props) => {
  const [allowCssTransition, setAllowCssTransition] = useGlobal("allowCssTransition");

  useEffect(() => {
    // Prevent animation to trigger after hydration
    setAllowCssTransition(true);
  }, []);

  return (
    <>
      {window && <AdblockDetectorContainer />}
      {window && <WebPSupport />}
      {window && <UserAuth />}
      {window && <Responsive />}
      {window && <RampContainer />}
      <RiotAssetsProvider staging={process.env.RIOT_PATCH_ASSETS === "staging"} ssr>
        <UGGAssetsProvider staging={process.env.RIOT_PATCH_ASSETS === "staging"}>
          <LightModeProvider>
            <AppDataInitializer>
              <Routes />
            </AppDataInitializer>
          </LightModeProvider>
        </UGGAssetsProvider>
      </RiotAssetsProvider>
    </>
  );
};

export default App;
