import { parseDescription } from "../ItemParsers";

export const getItemType = (itemId: string, itemsList: any) => {
  const itemClassification = {
    article: "an",
    type: "item",
  };
  const id = parseInt(itemId);
  if (itemsList?.starterItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "starter item";
  }
  if (itemsList?.consumableItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "consumable item";
  }
  if (itemsList?.trinketItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "trinket item";
  }
  if (itemsList?.distributedItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "distributed item";
  }
  if (itemsList?.bootItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "boots item";
  }
  if (itemsList?.basicItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "basic item";
  }
  if (itemsList?.epicItems.includes(id)) {
    itemClassification.type = "epic item";
  }
  if (itemsList?.legendaryItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "legendary item";
  }
  if (itemsList?.mythicItems.includes(id)) {
    itemClassification.article = "a";
    itemClassification.type = "mythic item";
  }
  if (itemsList?.ornnItems.includes(id)) {
    itemClassification.article = "an";
    itemClassification.type = "ornn item";
  }
  return {
    article: itemClassification.article,
    type: itemClassification.type,
  };
};

export const getItemDescription = (itemId: string, itemData: any, itemWiki: any, { render }: { render?: boolean } = {}) => {
  const { name, description, gold } = itemData || {};
  const { article, type } = getItemType(itemId, itemWiki);

  if (!name || !description || !gold || !article || !type) {
    return "Item description";
  }

  const { stats, allStats, actives, passives } = parseDescription(description);
  // console.log(actives);

  const renderStats = (stats: string[], { render }: { render?: boolean } = {}) => {
    if (stats?.length > 0 && stats[0]) {
      const statsString = stats.join(", ");
      if (render) {
        return (
          <>
            {" "}
            and grants <strong>{statsString}</strong>
          </>
        );
      } else return ` and grants ${statsString}`;
    } else return "";
  };

  const parseActives = (actives: string[]) => {
    const parsedActives = actives.map((active) => {
      const removeSuffix = active.replace("Active - ", "");
      const split = removeSuffix.split(":");
      const activeName = split[0];
      return activeName;
    });
    return parsedActives;
  };

  const parsePassives = (passives: string[]) => {
    const parsedPassives = passives.map((passive) => {
      const split = passive.split(":");
      const passiveName = split[0];
      return passiveName;
    });
    return parsedPassives;
  };

  const renderActivesAndPassives = (
    actives: string[],
    passives: string[],
    stats: string[],
    { render }: { render?: boolean } = {},
  ) => {
    const isStats = renderStats(stats);
    if (!actives && !passives) return "";
    if (actives?.length > 0 && (!passives || !passives[0])) {
      const parsedActives = parseActives(actives);
      if (actives.length > 1) {
        if (render) {
          return (
            <>
              {" "}
              and Unique Actives (<strong>{parsedActives.join(", ")}</strong>)
            </>
          );
        } else return ` and Unique Actives (${parsedActives.join(", ")})`;
      } else {
        if (render) {
          return (
            <>
              {isStats ? " and" : " and grants"} a Unique Active (<strong>{parsedActives[0]}</strong>)
            </>
          );
        } else return ` ${isStats ? "and" : "and grants"} a Unique Active (${parsedActives[0]})`;
      }
    }
    if ((!actives || !actives[0]) && passives?.length > 0) {
      const parsedPassives = parsePassives(passives);
      if (passives.length > 1) {
        if (render) {
          return (
            <>
              {isStats ? " and" : " and grants"} Unique Passives (<strong>{parsedPassives.join(", ")}</strong>)
            </>
          );
        } else return ` ${isStats ? "and" : "and grants"} Unique Passives (${parsedPassives.join(", ")})`;
      } else {
        if (render) {
          return (
            <>
              {" "}
              and a Unique Passive (<strong>{parsedPassives[0]}</strong>)
            </>
          );
        } else return ` and a Unique Passive (${parsedPassives[0]})`;
      }
    }
    if (actives?.length > 0 && passives?.length > 0) {
      const parsedPassives = parsePassives(passives);
      const parsedActives = parseActives(actives);
      if (render) {
        return (
          <>
            , a Unique Active (<strong>{parsedActives[0]}</strong>),
            {passives.length > 1 ? " and Unqiue Passives" : " and a Unique Passive"} (<strong>{parsedPassives.join(", ")}</strong>
            )
          </>
        );
      } else
        return `, a Unique Active (${parsedActives[0]}), ${
          passives.length > 1 ? "and Unqiue Passives" : "and a Unique Passive"
        } (${parsedPassives.join(", ")})`;
    }
    return "";
  };

  if (render) {
    return (
      <h2>
        {name} is {article} <strong>{type}</strong> in League of Legends. {name} costs <strong>{gold?.total} gold</strong>
        {renderStats(stats, { render })}
        {renderActivesAndPassives(actives, passives, stats, { render })}.
      </h2>
    );
  } else {
    return `${name} is ${article} ${type} in League of Legends. ${name} costs ${gold?.total} gold${renderStats(
      stats,
    )}${renderActivesAndPassives(actives, passives, stats)}.`;
  }
};
