import React from "react";
import TooltipContainer from "../TooltipContainer";
import RuneTooltip from "../tooltipInfoTypes/RuneTooltip";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const Rune = (props) => {
  const { getRuneImg, getRuneJSON, useRiotRunes, getLegacyRunePatch } = getRiotAssetsContext();
  const { children, runeId, patch, language, className, style, disableTooltip, ssr, skip, webp } = props;

  const latestPatch = getLegacyRunePatch(runeId, patch);
  const { data: runes, loading: fetchingRunes, error: errorRunes } = useRiotRunes({ patch: latestPatch, ssr, language, skip });

  const data = getRuneJSON(Number(runeId), { patch: latestPatch, language, optionalData: runes });
  const imgSrc = getRuneImg(runeId, { patch: latestPatch, optionalData: runes, webp });
  const name = (data && data.name) || "";
  let alt = name;
  if (data && data.type === "tree") {
    alt = `The Rune Tree ${name}`;
  } else if (data && data.type === "keystone") {
    alt = `The Keystone ${name}`;
  } else if (data && data.type === "rune") {
    alt = `The Rune ${name}`;
  }

  const tooltipInfo = <RuneTooltip runeId={runeId} patch={patch} language={language} />;

  if (typeof children === "function") {
    return (
      <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
        {children({ imgSrc, runeName: name, tooltipInfo })}
      </TooltipContainer>
    );
  }

  return (
    <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
      <img className={className} style={style} src={imgSrc} alt={alt} />
    </TooltipContainer>
  );
};

export default Rune;

// Rune.whyDidYouRender = true;
