import { useEffect, useMemo } from "react";
import { useGlobal } from "reactn";
import Fuse from "fuse.js";
import { STATIC_LOL_URL } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
// import { getNormalizedItemName } from "@outplayed/riot-assets";
// import { availableItems } from "../../components/Items/ItemsData";
import {
  ItemPage,
  SearchBarPage,
  SearchPage,
  SearchBarPagesType,
  SearchPagesType,
} from "@ugg/shared/interfaces/search-pages.interface";

import { getChampionBuildUrl, getChampionCountersUrl } from "@ugg/shared/routes/app-routes";
import { useUGGAssetsContext } from "@ugg/shared/components/UGGAssetsProvider";

/***********************************
 * Search Bar Pages
 ***********************************/
export enum VALID_SEARCH_SECTIONS {
  BUILD = "build",
  PROBUILDS = "probuild",
  COUNTERS = "counter",
  ITEMS = "item",
  SUMMONER = "summoner",
}

export const SECTIONS = [
  { id: VALID_SEARCH_SECTIONS.BUILD, title: "Build", suffix: "", path: getChampionBuildUrl },
  {
    id: VALID_SEARCH_SECTIONS.PROBUILDS,
    title: "Pro Builds",
    suffix: " Pro Builds",
    path: (champId: string) => `https://probuildstats.com/champion/${champId}`,
  },
  { id: VALID_SEARCH_SECTIONS.COUNTERS, title: "Counters", suffix: " Counters", path: getChampionCountersUrl },
];
export const ITEM_SECTIONS = [{ id: VALID_SEARCH_SECTIONS.ITEMS, title: "Items", suffix: "", path: "" }];

// function getItemPages(name: string, itemId: string): ItemPage {
//   return {
//     key: name,
//     displayName: name,
//     url: `/lol/items/${getNormalizedItemName(itemId)}`,
//     itemId: itemId,
//     section: VALID_SEARCH_SECTIONS.ITEMS,
//   };
// }

export function getSearchBarPage(
  key: string,
  displayName: string,
  champId: string,
  lowerId: string,
  pathFn: Function,
  section: VALID_SEARCH_SECTIONS,
): SearchBarPage {
  const isNickname = key.toLowerCase() !== lowerId && key.toLowerCase() !== displayName.toLowerCase();

  if (section === VALID_SEARCH_SECTIONS.PROBUILDS) {
    return {
      key: key,
      displayName: displayName,
      url: pathFn(lowerId),
      champId: champId,
      section,
      isNickname,
      targetHref: true,
    };
  }

  return {
    key: key,
    displayName: displayName,
    url: pathFn(lowerId),
    champId: champId,
    section,
    isNickname,
  };
}

interface SearchBarPagesOptions {
  language: string;
  ssr: boolean;
  skip: boolean;
  exclude: Array<VALID_SEARCH_SECTIONS>;
}

export function useSearchBarPages(options?: Partial<SearchBarPagesOptions>): {
  data: SearchBarPagesType | null;
  loading: boolean;
  error: boolean;
} {
  const { language = "", ssr = false, skip = false, exclude = [] } = options || {};
  const [savedResults, setSavedResults] = useGlobal("search-bar-pages");
  const { useChampionMini, getNormalizedChampionName, getItems } = getRiotAssetsContext();
  const championMini = useChampionMini({ language, ssr, skip }); // includes backup champions
  const itemsJSON = getItems();

  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const championNicknames = useJSONFetcher<Record<string, string[]>>(url, { ssr, skip });

  const searchBarPages: SearchBarPagesType | null = useMemo(() => {
    if (savedResults) {
      return savedResults;
    } else if (!championMini.data || !championNicknames.data) {
      return null;
    }
    // Build search bar pages
    const FILTERED_SECTIONS = SECTIONS.filter((section) => !exclude.includes(section.id));
    const champPages = FILTERED_SECTIONS.map((curSection) => {
      const pages: Array<ReturnType<typeof getSearchBarPage>> = Object.values(championMini.data || {}).reduce(
        (accChampion, curChampion) => {
          const { name, key, id } = curChampion;
          const normalizedName = getNormalizedChampionName(key);
          const nicknames = championNicknames?.data?.[id] || [];

          const variations = new Set([id, name, ...(nicknames || [])]);
          const championPages = [...variations].map((variation) =>
            getSearchBarPage(
              `${variation}${curSection.suffix}`,
              `${name}${curSection.suffix}`,
              key,
              normalizedName,
              curSection.path,
              curSection.id,
            ),
          );
          return accChampion.concat(championPages);
        },
        [] as SearchBarPage[],
      );
      return { title: curSection.title, pages };
    });
    // const itemPages =
    //   itemsJSON &&
    //   !exclude.includes(VALID_SEARCH_SECTIONS.ITEMS) &&
    //   ITEM_SECTIONS.map((curSection) => {
    //     const pages = Object.keys(itemsJSON)
    //       // .filter(id => availableItems.includes(parseInt(id)))
    //       .map((itemId) => getItemPages(itemsJSON[itemId].name, itemId));
    //     return { title: curSection.title, pages };
    //   });
    // const searchBarPages = [...champPages, ...(itemPages || [])];
    const searchBarPages = [...champPages];
    return searchBarPages;
  }, [savedResults, championMini.data, championNicknames.data, itemsJSON]);

  // Save results globally so we don't have to build the pages again
  useEffect(() => {
    if (!savedResults && searchBarPages) {
      setSavedResults(searchBarPages);
    }
  }, [savedResults, searchBarPages]);

  if (championMini.loading || championNicknames.loading) {
    return { data: null, error: false, loading: !championMini.loading || !championNicknames.loading };
  } else if (championMini.error || championNicknames.error) {
    return { data: null, loading: false, error: !championMini.error || !championNicknames.error };
  } else {
    return { data: searchBarPages, loading: false, error: false };
  }
}

/****************************************************
 * Search Pages (OpenSearch, Search Bar Matching)
 ****************************************************/
function getSearchPages(name: string, lowerId: string, displayName: string, champId: string): SearchPage[] {
  const isNickname = name.toLowerCase() !== lowerId && name.toLowerCase() !== displayName.toLowerCase();

  return [
    {
      key: `${name}`,
      displayName: `${displayName}`,
      id: champId,
      url: `/lol/champions/${lowerId}/build`,
      isNickname,
      isDefaultPage: true,
    },
    {
      key: `${name} Items`,
      displayName: `${displayName} Items`,
      id: champId,
      url: `/lol/champions/${lowerId}/items`,
      isNickname,
    },
    {
      key: `${name} Spells`,
      displayName: `${displayName} Spells`,
      id: champId,
      url: `/lol/champions/${lowerId}/spells-abilities`,
      isNickname,
    },
    {
      key: `${name} Abilities`,
      displayName: `${displayName} Abilities`,
      id: champId,
      url: `/lol/champions/${lowerId}/spells-abilities`,
      isNickname,
    },
    {
      key: `${name} Runes`,
      displayName: `${displayName} Runes`,
      id: champId,
      url: `/lol/champions/${lowerId}/runes`,
      isNickname,
    },
    {
      key: `${name} Rune Sets`,
      displayName: `${displayName} Rune Sets`,
      id: champId,
      url: `/lol/champions/${lowerId}/runes-sets`,
      isNickname,
    },
    {
      key: `${name} Counters`,
      displayName: `${displayName} Counters`,
      id: champId,
      url: `/lol/champions/${lowerId}/counters`,
      isNickname,
    },
    {
      key: `${name} Matchups`,
      displayName: `${displayName} Matchups`,
      id: champId,
      url: `/lol/champions/${lowerId}/matchups`,
      isNickname,
    },
    {
      key: `${name} Duos`,
      displayName: `${displayName} Duos`,
      id: champId,
      url: `/lol/champions/${lowerId}/duos`,
      isNickname,
    },
    {
      key: `${name} Pro Builds`,
      displayName: `${displayName} Pro Builds`,
      id: champId,
      url: `https://probuildstats.com/champion/${lowerId}`,
      isNickname,
      targetHref: true,
    },
  ];
}

export function useSearchPages(options?: { language: string; ssr: boolean; skip: boolean }): {
  data: SearchPagesType | null;
  loading: boolean;
  error: boolean;
} {
  const { language = "", ssr = false, skip = false } = options || {};
  const [savedResults, setSavedResults] = useGlobal("search-pages");
  const { useChampionMini, getNormalizedChampionName, getItems } = getRiotAssetsContext();
  const championMini = useChampionMini({ language, ssr, skip }); // includes backup champions
  const itemsJSON = getItems();
  const { staging } = useUGGAssetsContext();
  const envDir = staging ? "staging" : "prod";
  const url = `${STATIC_LOL_URL}/riot_patch_update/${envDir}/champion-nicknames.json`;
  const championNicknames = useJSONFetcher<Record<string, string[]>>(url, { ssr, skip });

  const searchPages: SearchPagesType | null = useMemo(() => {
    if (savedResults) {
      return savedResults;
    } else if (!championMini.data || !championNicknames.data) {
      return null;
    }
    // Build search bar pages
    const champPages: ReturnType<typeof getSearchPages> = Object.values(championMini.data).reduce((accChampion, curChampion) => {
      const { name, key, id } = curChampion;
      const normalizedName = getNormalizedChampionName(key);
      const nicknames = championNicknames?.data?.[id] || [];

      const variations = new Set([id, name, ...(nicknames || [])]);
      const championPages: ReturnType<typeof getSearchPages> = [...variations].reduce((acc, variation) => {
        const pages = getSearchPages(variation, normalizedName, name, key);
        return acc.concat(pages);
      }, [] as SearchPage[]);
      return accChampion.concat(championPages);
    }, [] as SearchPage[]);

    // const itemPages: Array<ReturnType<typeof getItemPages>> =
    //   itemsJSON &&
    //   Object.keys(itemsJSON)
    //     // .filter(id => availableItems.includes(parseInt(id)))
    //     .map((itemId) => getItemPages(itemsJSON[itemId].name, itemId));
    // const searchBarPages = [...champPages, ...itemPages];
    const searchBarPages = [...champPages];
    return searchBarPages;
  }, [savedResults, championMini.data, championNicknames.data, itemsJSON]);

  // Save results globally so we don't have to build the pages again
  useEffect(() => {
    if (!savedResults && searchPages) {
      setSavedResults(searchPages);
    }
  }, [savedResults, searchPages]);

  if (championMini.loading || championNicknames.loading) {
    return { data: null, error: false, loading: !championMini.loading || !championNicknames.loading };
  } else if (championMini.error || championNicknames.error) {
    return { data: null, loading: false, error: !championMini.error || !championNicknames.error };
  } else {
    return { data: searchPages, loading: false, error: false };
  }
}

const fuseOpts = {
  shouldSort: true,
  includeScore: true,
  // tokenize: true,
  // matchAllTokens: true,
  keys: ["key"],
};

export function useBestSearchMatch() {
  const { data: searchPages } = useSearchPages();

  return (query: string) => {
    if (searchPages) {
      var fuse = new Fuse(searchPages, fuseOpts);
      let res = fuse.search((query && query.trim()) || "");

      return res;
    } else {
      return [];
    }
  };
}
