import React, { useMemo } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { HistoricRanks } from "@ugg/shared/components/common/HistoricRanks/HistoricRanks";
import PromoProgress from "./PromoProgress";
import ChampionPerformanceList from "./ChampionPerformanceList";
import RecentMatches from "./RecentMatches";
import { getS13RankUrl } from "@outplayed/riot-assets";
import { getTierColor, getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { getRoleSVG } from "@ugg/shared/utils/role-helpers";
import { getMSWinRateColor } from "../multi-search-helpers";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";
import { MatchSummaryInfo } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";

const MORE_MULTI_SEARCH_DATA = gql`
  query MoreMultiSearchData(
    $riotUserName: String!
    $riotTagLine: String!
    $regionId: String!
  ) {
    getSummonerRankSnapshots(
      queueType: [420],
      regionId: $regionId,
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine
    ){
      insertedAt
      losses
      lp
      promoProgress
      queueId
      rank
      tier
      wins
    }
    fetchPlayerMatchSummaries(
      championId: [],
      page: 1,
      queueType: [420],
      regionId: $regionId,
      role: [],
      seasonIds: [${INTERNAL_CURRENT_SEASON}],
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine
    ) {
      matchSummaries {
        ${MatchSummaryInfo}
      }
    }
  }
`;

const MultiSearchPlayerContainer = (props) => {
  const { data, regionId } = props;
  const {
    bestChamps,
    rankData,
    roleStats,
    riotUserName,
    riotTagLine,
    totalGamesLastFifteen,
    winperc,
    winsLastFifteen,
    winstreak,
    worstChamps,
  } = data || {};

  const {
    data: additionalMultiSearchData,
    loading: loadingAdditionalMultiSearchData,
    error: errorAdditionalMultiSearchData,
  } = useQuery(MORE_MULTI_SEARCH_DATA, {
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
    },
  });

  const { getSummonerRankSnapshots, fetchPlayerMatchSummaries } = additionalMultiSearchData || {};
  const { matchSummaries } = fetchPlayerMatchSummaries || {};

  const { tier, rank, lp, promoProgress, wins, losses } = rankData || {};
  const totalGames = (wins || 0) + (losses || 0);
  const winRate = Math.round(((wins || 0) / totalGames) * 100);

  return (
    <div className="multisearch-player">
      <div className="player_historic-ranks">
        <HistoricRanks riotUserName={riotUserName} riotTagLine={riotTagLine} regionId={regionId} queueType={420} />
      </div>
      <div className="player_info">
        <div className="rank-image-container">
          <img src={getS13RankUrl(((rankData && rankData.tier) || "unranked").toLowerCase(), true)} />
        </div>
        <Link className="summoner-name" to={getProfileOverviewUrl(regionId, riotUserName, riotTagLine)}>
          {riotUserName} #{riotTagLine}
        </Link>
        <div className="current-rank">
          {!tier ? (
            <strong className="current-rank_unranked">Unranked</strong>
          ) : (
            <div className="flex-center">
              <strong style={{ color: getTierColor(tier) }}>{getProBuildRankName(tier, rank)}</strong>
              <span className="backslash">&nbsp;/&nbsp;</span>
              {promoProgress ? <PromoProgress promoProgress={promoProgress} /> : <span className="off-white">{lp} LP</span>}
            </div>
          )}
        </div>
        <div className="current-games">
          {isNaN(winRate) ? (
            <span>&mdash;</span>
          ) : (
            <span>
              <strong>{winRate}%</strong>
              <span className="backslash"> / </span>
              <span className="off-white">{numberWithCommas(totalGames)} games</span>
            </span>
          )}
        </div>
      </div>
      <div className="player_role-distribution">
        {roleStats.map((role) => {
          if (role.games === 0) return null;

          const roleWinRate = Math.round(((role.wins || 0) / role.games) * 100);
          return (
            <div key={role.roleName} className="role-container">
              <div className="role-img">{getRoleSVG(role.roleName)}</div>
              <strong style={{ color: getMSWinRateColor(roleWinRate) }}>{roleWinRate}% WR</strong>
              <span className="off-white">{numberWithCommas(role.games)} games</span>
            </div>
          );
        })}
      </div>
      <div className="player_streak">
        {winstreak !== 0 && (
          <div
            className={classnames("game-streak", {
              "game-streak_loss": winstreak < 0,
              "game-streak_win": winstreak > 0,
              "game-streak_gold-win": winstreak >= 5,
            })}
          >
            <span>
              <strong>{Math.abs(winstreak)} game</strong> {winstreak > 0 ? "win" : "loss"} streak
            </span>
          </div>
        )}
      </div>
      <ChampionPerformanceList title={"Best Champions"} list={bestChamps} />
      <ChampionPerformanceList title={"Worst Champions"} list={worstChamps} />
      <hr />
      <RecentMatches matches={matchSummaries || []} rankSnapshots={getSummonerRankSnapshots || []} />
    </div>
  );
};

export default MultiSearchPlayerContainer;
