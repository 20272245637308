import { useLocation } from "react-router-dom";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { useSeoPages } from "./seo-helpers";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "../pages/champion-pages";
import { SPECIAL_TIER_LIST_PAGES } from "../pages/tier-list-pages";
import { QueueTypeS } from "./queue-type-helpers";
import { getSpecialGameModeChampionBuildUrl, SpecialGameModeChampionBuildParams } from "@ugg/shared/routes/app-routes";

import { useTierList } from "@ugg/shared/api/requests/tier-lists/tier-list";
import { useArenaTierList } from "@ugg/shared/api/requests/tier-lists/arena-tier-list";
import { useArenaDuoTierList } from "@ugg/shared/api/requests/tier-lists/arena-duo-tier-list";

export const availableSpecialModes = [
  QueueTypeS.ARAM,
  QueueTypeS.NEXUS_BLITZ,
  QueueTypeS.PICK_URF,
  QueueTypeS.ARURF,
  QueueTypeS.ONE_FOR_ALL,
  // QueueTypeS.ULT_SPELLBOOK,
  QueueTypeS.ARENA,
  QueueTypeS.ARENA_DUO,
] as const;

type SpecialGameModeConfig = {
  [key in (typeof availableSpecialModes)[number]]: {
    newTag: boolean;
    urlName: string; //"arurf",
    queueType: (typeof availableSpecialModes)[number]; // "arurf",
    buildPageName: SPECIAL_CHAMPION_PAGES; //"overview_arurf", type build pages
    getSpecialGameModeBuildUrl: (champion: string, params?: SpecialGameModeChampionBuildParams) => string;
    tierListPageName: SPECIAL_TIER_LIST_PAGES; //"overview_arurf", type build pages
    queueTypeName: string; // "ARURF",
    seo: {
      h1Suffix: string;
      blurbType: string; //"ARURF",
      build: keyof ReturnType<typeof useSeoPages> | undefined;
      // "champion_overview_arurf",
    };
    uggApi: {
      hasRank: boolean;
      hasRole: boolean;
    };
    tierListSettings: {
      title: { main: string };
      showAgainst: boolean;
      showBan: boolean;
      showRole: boolean;
      showTier: boolean;
      filterProps?: {
        disable?: string[];
      };
    };
  };
};

export const SPECIAL_GAME_MODES: SpecialGameModeConfig = {
  [QueueTypeS.ARURF]: {
    newTag: true,
    urlName: "arurf",
    queueType: QueueTypeS.ARURF,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "arurf", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.ARURF_TIER_LIST,
    queueTypeName: "ARURF",
    seo: {
      h1Suffix: "ARURF Build & Runes",
      blurbType: "ARURF",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF,
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "ARURF Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank"],
      },
    },
  },
  [QueueTypeS.PICK_URF]: {
    newTag: true,
    urlName: "urf",
    queueType: QueueTypeS.PICK_URF,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_PICK_URF,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "urf", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.PICK_URF_TIER_LIST,
    queueTypeName: "URF",
    seo: {
      h1Suffix: "URF Build & Runes",
      blurbType: "URF",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_URF,
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "URF Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank"],
      },
    },
  },
  [QueueTypeS.NEXUS_BLITZ]: {
    newTag: true,
    urlName: "nexus-blitz",
    queueType: QueueTypeS.NEXUS_BLITZ,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "nexus-blitz", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.NEXUS_BLITZ_TIER_LIST,
    queueTypeName: "Nexus Blitz",
    seo: {
      h1Suffix: "Nexus Blitz Build & Runes",
      blurbType: "Nexus Blitz",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ,
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "Nexus Blitz Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: false,
      filterProps: {
        disable: ["rank", "role"],
      },
    },
  },
  [QueueTypeS.ONE_FOR_ALL]: {
    newTag: true,
    urlName: "one-for-all",
    queueType: QueueTypeS.ONE_FOR_ALL,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "one-for-all", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.ONE_FOR_ALL_TIER_LIST,
    queueTypeName: "One for All",
    seo: {
      h1Suffix: "One for All Build & Runes for",
      blurbType: "One for All",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL,
    },
    uggApi: {
      hasRank: false,
      hasRole: true,
    },
    tierListSettings: {
      title: { main: "One for All Tier List" },
      showAgainst: true,
      showBan: true,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["rank", "role"],
      },
    },
  },
  [QueueTypeS.ARENA]: {
    newTag: true,
    urlName: "arena",
    queueType: QueueTypeS.ARENA,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "arena", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.ARENA_TIER_LIST,
    queueTypeName: "Arena",
    seo: {
      h1Suffix: "Arena Build",
      blurbType: "Arena",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA,
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "Arena Tier List" },
      showAgainst: false,
      showBan: true,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank", "queueType"],
      },
    },
  },
  [QueueTypeS.ARENA_DUO]: {
    newTag: true,
    urlName: "arena",
    queueType: QueueTypeS.ARENA,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "arena", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.ARENA_DUO_TIER_LIST,
    queueTypeName: "Arena",
    seo: {
      h1Suffix: "Arena Build",
      blurbType: "Arena",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA,
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "Arena Duos" },
      showAgainst: false,
      showBan: true,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["role", "rank", "queueType"],
      },
    },
  },
  [QueueTypeS.ARAM]: {
    newTag: false,
    urlName: "aram",
    queueType: QueueTypeS.ARAM,
    buildPageName: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM,
    getSpecialGameModeBuildUrl: (champion, params?) => getSpecialGameModeChampionBuildUrl(champion, "aram", params),
    tierListPageName: SPECIAL_TIER_LIST_PAGES.ARAM_TIER_LIST,
    queueTypeName: "ARAM",
    seo: {
      h1Suffix: "ARAM Build & Runes",
      blurbType: "ARAM",
      build: SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM,
    },
    uggApi: {
      hasRank: false,
      hasRole: false,
    },
    tierListSettings: {
      title: { main: "ARAM Tier List" },
      showAgainst: false,
      showBan: false,
      showRole: false,
      showTier: true,
      filterProps: {
        disable: ["rank", "role"],
      },
    },
  },
};

export const SPECIAL_GAME_MODE_TIER_LISTS: { [key: string]: SPECIAL_TIER_LIST_PAGES } = {
  [QueueTypeS.ARAM]: SPECIAL_TIER_LIST_PAGES.ARAM_TIER_LIST,
  [QueueTypeS.NEXUS_BLITZ]: SPECIAL_TIER_LIST_PAGES.NEXUS_BLITZ_TIER_LIST,
  [QueueTypeS.PICK_URF]: SPECIAL_TIER_LIST_PAGES.PICK_URF_TIER_LIST,
  [QueueTypeS.ARURF]: SPECIAL_TIER_LIST_PAGES.ARURF_TIER_LIST,
  [QueueTypeS.ONE_FOR_ALL]: SPECIAL_TIER_LIST_PAGES.ONE_FOR_ALL_TIER_LIST,
};

export function useActiveSpecialModes(options: { isChampBuildPage?: boolean; ssr?: boolean } = {}) {
  const { isChampBuildPage = false, ssr = false } = options;
  const activeModeLabel = (queueType: string) => `active-mode-${queueType}`;

  const normalTierList = Object.values(SPECIAL_GAME_MODES).filter((mode) => ![QueueTypeS.ARENA].includes(mode.queueType));
  const liveSpecialModes = new Set<(typeof availableSpecialModes)[number]>();

  for (let i = 0; i < normalTierList.length; i++) {
    const { queueType, tierListPageName } = normalTierList[i];
    const options = { queueType, tierListPageName };

    const { data, loading, error } = useTierList(tierListPageName, {
      params: options,
      ssr,
      customCacheKey: activeModeLabel(queueType),
      onCompleted: (url, json) => !!json,
    });

    if (data && !error) {
      liveSpecialModes.add(queueType);
    }
  }

  const arenaTierListData = useArenaTierList({
    ssr,
    customCacheKey: activeModeLabel(QueueTypeS.ARENA),
    onCompleted: (url, json) => !!json,
  });
  if (arenaTierListData?.data && !arenaTierListData?.error) {
    liveSpecialModes.add(QueueTypeS.ARENA);
  }

  const arenaDuoTierListData = useArenaDuoTierList({
    ssr,
    customCacheKey: activeModeLabel(QueueTypeS.ARENA_DUO),
    onCompleted: (url, json) => !!json,
  });
  if (arenaDuoTierListData?.data && !arenaDuoTierListData?.error) {
    liveSpecialModes.add(QueueTypeS.ARENA_DUO);
  }

  if (isChampBuildPage) {
    liveSpecialModes.delete(QueueTypeS.ARENA_DUO);
  }

  // Move ARAM to the end
  if (liveSpecialModes.has(QueueTypeS.ARAM)) {
    liveSpecialModes.delete(QueueTypeS.ARAM);
    liveSpecialModes.add(QueueTypeS.ARAM);
  }

  return [...liveSpecialModes];
}

// export function getSpecialGameModeTierListSettings(queueType) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (queueType === value.queueType) {
//       return value.tierListSettings;
//     }
//   }
// }

// export function getSpecialGameModeUggApiSettings(queueType) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (queueType === value.queueType) {
//       return value.uggApi;
//     }
//   }
// }

// export function getSpecialGameModeTierListTitles() {
//   let titles = {};
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     titles[`${value.urlName}-tier-list`] = value.tierListSettings.title;
//   }
//   return titles;
// }

// export function getSpecialAbsolutePage(page) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === `${value.urlName}-tier-list`) {
//       return "tier-list";
//     }
//   }
//   return null;
// }

// export function getSpecialBlurbType(page) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName) {
//       return value.seo.blurbType;
//     }
//   }
//   return null;
// }

// export function getSpecialUrlName() {
//   let urlNames = [];
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     urlNames.push(value.urlName);
//   }
//   return urlNames;
// }

// export function getSpecialSSRPage(page) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName) {
//       return true;
//     }
//   }
//   return null;
// }

// export function getSpecialGameModePaths(normalizedChampionName) {
//   let paths = [];

//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (value.active) {
//       paths.push({
//         path: `${AppRoutes.CHAMPION_ROOT}/${value.urlName}/${normalizedChampionName}-${value.urlName}`,
//         label: value.queueTypeName,
//         new: value.newTag,
//         activePaths: [`${AppRoutes.CHAMPION_ROOT}/${value.urlName}/${normalizedChampionName}-${value.urlName}`],
//       });
//     }
//   }
//   return paths;
// }

// export function getSpecialTierLists(includedLolPrefix = true) {
//   let tierLists = [];
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (includedLolPrefix) {
//       tierLists.push(`${AppRoutes.TIER_LIST_ROOT}/${value.urlName}-tier-list`);
//     } else {
//       tierLists.push(`${value.urlName}-tier-list`);
//     }
//   }
//   return tierLists;
// }

// export function getSpecialGameModeTierListPaths() {
//   let paths = [];
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (value.active) {
//       paths.push({
//         path: `${AppRoutes.TIER_LIST_ROOT}/${value.urlName}-tier-list`,
//         label: `${value.queueTypeName} Tier List`,
//         new: value.newTag,
//         activePaths: [`${AppRoutes.TIER_LIST_ROOT}/${value.urlName}-tier-list`],
//       });
//     }
//   }
//   return paths;
// }

// export function getSpecialPageH1Suffix(page) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName) {
//       return value.seo.h1Suffix;
//     }
//   }
//   return null;
// }

// export function getSpecialGameModeOverviewPage(pathname) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (pathname.includes(`${AppRoutes.CHAMPION_ROOT}/${value.urlName}`)) {
//       return value.buildPageName;
//     }
//   }
//   return "";
// }

// export function getSpecialGameModeSeoName(pathname) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (pathname.includes(`${AppRoutes.CHAMPION_ROOT}/${value.urlName}`)) {
//       return value.seo.build;
//     }
//   }
//   return false;
// }

// export function getSpecialGameModeSeoNameFromPage(page) {
//   for (let [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName) {
//       return value.seo.build;
//     }
//   }
// }

// export function getSpecialGameModeFilterProps(page) {
//   for (let [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName) {
//       let filterProps = {
//         collapsable: false,
//         overrideDefaultFilters: {
//           queueType: value.queueType,
//         },
//       };
//       return filterProps;
//     }
//   }
// }

// export function getSpecialGameModeStatsTablesFilterProps(queue_type_tier_list_page) {
//   for (let [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (queue_type_tier_list_page === `${value.urlName}-tier-list`) {
//       let filterProps: FilterManagerProps = {
//         overrideDefaultFilters: {
//           queueType: value.queueType,
//         },
//         page: "tier-list",
//         enable: [],
//         disable: value.tierListSettings.filterProps.disable,
//         allRoles: false,
//         collapsable: false,
//       };
//       return filterProps;
//     }
//   }
// }

// export function getQueryParams(pathname: string, queryParams: object = {}): object {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (pathname.includes(`/${value.urlName}`) || pathname.includes(`${value.urlName}-tier-list`)) {
//       return {
//         ...queryParams,
//         queueType: value.queueType,
//       };
//     }
//   }

//   return {};
// }

// export function getSpecialQueueTypeBuildOrTierList(page, location) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName || location.pathname.includes(`${value.urlName}-tier-list`)) {
//       return value.queueType;
//     }
//   }
// }

// function getSpecialFromTierList(location) {
//   if (!location || !location.pathname) {
//     return null;
//   }
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (location.pathname.includes(`${value.urlName}-tier-list`)) {
//       return value;
//     }
//   }
//   return null;
// }

// export function getSpecialQueueType() {
//   const location = useLocation();
//   let special = getSpecialFromTierList(location);
//   if (special) {
//     return special.queueType;
//   }
// }

// export function getQueueTypeName(location) {
//   let special = getSpecialFromTierList(location);
//   if (special) {
//     return special.queueTypeName;
//   }
// }

// export function getSpecialGameModeBuildPath(location, normalizedChampionName): string | null {
//   let special = getSpecialFromTierList(location);
//   if (special) {
//     return `${AppRoutes.CHAMPION_ROOT}/${special.urlName}/${normalizedChampionName}-${special.urlName}`;
//   }

//   return null;
// }

// export function getSpecialQueryParams(location, prevLocation) {
//   // Compare the previous pathname to this one and if we are coming from the aram page, add the queueType aram to the filter; because that page won't have it in the filter.
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (
//       location &&
//       prevLocation &&
//       location.pathname !== prevLocation.pathname &&
//       prevLocation.pathname.includes(`${value.urlName}-tier-list`) &&
//       !location.pathname.includes("tier-list")
//     ) {
//       return {
//         queueType: value.queueType,
//       };
//     }
//   }
//   return null;
// }

// export function getForcedParams(location) {
//   if (!location || !location.pathname) {
//     return null;
//   }
//   const pathname = location.pathname;
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (pathname.includes(`/${value.urlName}`) || pathname.includes(`${value.urlName}-tier-list`)) {
//       let newForcedParams = {
//         queueType: value.queueType,
//       };
//       return newForcedParams;
//     }
//   }
// }

// export function isSpecialGameModeByQueueType(queueType) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (queueType === value.queueType) {
//       return true;
//     }
//   }
//   return false;
// }

// export function isSpecialGameModeByPage(page) {
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (page === value.buildPageName) {
//       return true;
//     }
//   }
//   return false;
// }

// export function showBuildToughestMatchups(location) {
//   if (!location) {
//     return true;
//   }
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (location.pathname.includes(`${AppRoutes.CHAMPION_ROOT}/${value.urlName}`)) {
//       return value.tierListSettings.showAgainst;
//     }
//   }
//   return true;
// }

// export function showBuildOTPStats(location) {
//   if (!location) {
//     return true;
//   }
//   for (const [key, value] of Object.entries(SPECIAL_GAME_MODES)) {
//     if (location.pathname.includes(`${AppRoutes.CHAMPION_ROOT}/${value.urlName}`)) {
//       return false;
//     }
//   }
//   return true;
// }
