import { round } from "@ugg/shared/utils/math";

export function calculateKDA(kills: number, deaths: number, assists: number) {
  if (deaths === 0) {
    if (kills > 0 || assists > 0) {
      return "Perfect";
    } else {
      return "0.00";
    }
  }

  return round((kills + assists) / deaths, 2).toFixed(2);
}

export function getKdaColor(kda: string | number) {
  if (kda === "Perfect") {
    return "#FF9B00";
  }

  const kdaNumber = typeof kda === "string" ? parseFloat(kda) : kda;

  if (kdaNumber >= 5) {
    return "#FF9B00";
  } else if (kdaNumber >= 3) {
    return "#3273FA";
  } else if (kdaNumber >= 1) {
    return "#FFFFFF";
  }
}

export function getKdaColorClassName(kda: string | number) {
  if (kda === "Perfect") {
    return "volxd-tier";
  } else if (kda === "0.00") {
    return "shinggo-tier";
  }

  const kdaNumber = typeof kda === "string" ? parseFloat(kda) : kda;

  if (kdaNumber >= 5) {
    return "volxd-tier";
  } else if (kdaNumber >= 3) {
    return "great-tier";
  } else if (kdaNumber >= 1) {
    return "okay-tier";
  } else {
    return "shinggo-tier";
  }
}

export function getKdaColorBestChampsClassName(kda: string | number) {
  if (kda === "Perfect") {
    return "volxd-tier";
  } else if (kda === "0.00") {
    return "gray-okay-tier";
  }

  const kdaNumber = typeof kda === "string" ? parseFloat(kda) : kda;

  if (kdaNumber >= 5) {
    return "volxd-tier";
  } else if (kdaNumber >= 3) {
    return "great-tier";
  } else if (kdaNumber >= 1) {
    return "gray-okay-tier";
  }
}
