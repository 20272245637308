import { window } from "global";
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";

const Modal = React.forwardRef((props, ref) => {
  const modalRef = useRef();
  const { style, classNames, toggleModalOpen, onCancel, onApply, bDisableFooter } = props;

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef && modalRef.current && e.target !== null && !modalRef.current.contains(e.target)) {
        onApplyHandler();
      }
    };
    if (window) {
      document.addEventListener("click", handleOutsideClick, true);

      return () => document.removeEventListener("click", handleOutsideClick, true);
    }
  }, []);

  const onCancelHandler = (e) => {
    onCancel && onCancel();
    toggleModalOpen(false);
  };

  const onApplyHandler = () => {
    onApply && onApply();
    toggleModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    onCancel: onCancelHandler,
    onApply: onApplyHandler,
  }));

  let modalClassNames = "select_modal__modal-window";
  if (classNames) {
    modalClassNames += ` ${classNames}`;
  }

  return (
    <div className={modalClassNames} ref={modalRef} style={style}>
      <div>{props.children}</div>
      {!bDisableFooter && (
        <div className="selection-footer">
          <div className="clear" onClick={onCancelHandler} style={{ fontWeight: "normal" }}>
            Cancel
          </div>
          <div className="apply" onClick={onApplyHandler}>
            APPLY
          </div>
        </div>
      )}
    </div>
  );
});

const ModalContainer = React.forwardRef((props, ref) => {
  const modalTooltip = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    dataTip,
    disableTooltip,
    buttonClassName,
    buttonStyle,
    button,
    subtext,
    modalClassNames,
    modalStyle,
    onApply,
    onCancel,
    bDisableFooter,
    toggleOpen,
    bModalOpen,
    isFixed,
  } = props;

  const toggleModalOpen = (toggle = !modalOpen) => {
    if (toggle) {
      modalTooltip.current.forceClose();
    }

    toggleOpen ? toggleOpen() : setModalOpen(toggle);
  };

  const isModalOpen = (bModalOpen !== undefined && bModalOpen !== false) || modalOpen;
  const containerClassNames = classnames({
    "modal-filter": true,
    "modal-filter__open": isModalOpen,
  });

  return (
    <div className={containerClassNames}>
      {/*<div className="modal-filter__overlay" />*/}
      <div className="select_modal">
        {button && (
          <TooltipContainer ref={modalTooltip} tooltipInfo={dataTip} disableTooltip={disableTooltip}>
            <div className={`select_modal__toggle ${buttonClassName}`} onClick={() => toggleModalOpen()} style={buttonStyle}>
              {button}
            </div>
          </TooltipContainer>
        )}
        {isModalOpen && (
          <Modal
            ref={ref}
            toggleModalOpen={toggleModalOpen}
            classNames={modalClassNames}
            style={modalStyle}
            onApply={onApply}
            onCancel={onCancel}
            bDisableFooter={bDisableFooter}
          >
            {props.children}
          </Modal>
        )}
      </div>
    </div>
  );
});

export default ModalContainer;
