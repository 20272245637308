import React from "react";
import { Link, Redirect, Route } from "react-router-dom";
import TooltipContainer from "../TooltipContainer";
import ItemTooltip from "../tooltipInfoTypes/ItemTooltip";
import { getRiotAssetsContext, getIcon } from "@outplayed/riot-assets";

const Item = (props) => {
  const { getWorkingPatch, getLegacyItemPatch, getItems, useRiotItems, getItemImg, getItemImgFromSprite, useItemsWiki } =
    getRiotAssetsContext();
  const itemsList = useItemsWiki({ ssr: true });
  const allItems = itemsList.data;
  const ornnItems = allItems && allItems?.ornnItems;
  const { itemId, patch, language, spriteSize, isSprite, className, style, disableTooltip, enableLink, ssr, skip, webp } = props;
  const legacyPatch = getLegacyItemPatch(itemId, patch);
  const useLegacyPatch = getWorkingPatch(patch) !== getWorkingPatch(legacyPatch);

  const {
    data: legacyItems,
    loading: fetchingLegacyItems,
    error: errorLegacyItems,
  } = useRiotItems({ itemId, patch: legacyPatch, language, ssr, skip: !useLegacyPatch });

  const { data: items, loading: fetchingItems, error: errorItems } = useRiotItems({ itemId, patch, language, ssr, skip });

  const itemsJSON = items || legacyItems || getItems({ patch: useLegacyPatch ? legacyPatch : patch, language });
  const data = itemsJSON && itemsJSON[itemId];
  const latestPatch = items && data ? patch : legacyPatch;

  // console.log(typeof itemId)
  let img = null;
  if (data && data.image && data.image.s3Img) {
    const imgSrc = getIcon(`ugg/icons/orrn-items/${itemId}.png`);
    const name = (data && data.name) || "";
    let styleObj = { ...style };

    // If this should be styled like from the style sheet, tak teh styling from asyncGetItemImgFromSprite
    if (isSprite) {
      const size = spriteSize || 24;
      const scaleAmt = size / 64 || 1;
      img = (
        <div style={{ height: `${spriteSize || 24}px`, width: `${spriteSize || 24}px`, overflow: "hidden" }}>
          <div
            style={{
              height: "64px", // original size of the sprite image
              width: "64px",
              backgroundImage: `url(${imgSrc})`,
              backgroundRepeat: "no-repeat",
              zoom: scaleAmt,
              MozTransform: `scale(${scaleAmt})`,
              MozTransformOrigin: "0 0",
            }}
          />
        </div>
      );
    } else {
      img = <img className={className} style={styleObj} src={imgSrc} alt={name} />;
    }
  } else if (ornnItems?.includes(parseInt(itemId))) {
    const imgSrc = getIcon(`lol/ornn_items/${itemId}.webp`);
    const name = (data && data.name) || "";
    let styleObj = { ...style };
    // If this should be styled like from the style sheet, tak teh styling from asyncGetItemImgFromSprite
    if (isSprite) {
      const size = spriteSize || 24;
      const scaleAmt = size / 64 || 1;
      img = (
        <div style={{ height: `${spriteSize || 24}px`, width: `${spriteSize || 24}px`, overflow: "hidden" }}>
          <div
            style={{
              height: "64px", // original size of the sprite image
              width: "64px",
              backgroundImage: `url(${imgSrc})`,
              backgroundRepeat: "no-repeat",
              zoom: scaleAmt,
              MozTransform: `scale(${scaleAmt})`,
              MozTransformOrigin: "0 0",
            }}
          />
        </div>
      );
    } else {
      img = <img className={className} style={styleObj} src={imgSrc} alt={name} />;
    }
  } else if (isSprite) {
    const sprite = getItemImgFromSprite(itemId, { size: spriteSize || 24, patch: latestPatch, optionalData: itemsJSON, webp });
    img = (
      <div className={className} style={style}>
        {sprite}
      </div>
    );
  } else {
    const imgSrc = getItemImg(itemId, { patch: latestPatch, optionalData: itemsJSON, webp });
    const name = (data && data.name) || "";
    img = imgSrc ? <img className={className} style={style} src={imgSrc} alt={name} /> : <></>;
  }

  const tooltipInfo = <ItemTooltip itemId={itemId} patch={patch} language={language} />;

  if (enableLink) {
    return (
      <Link
        to={{
          pathname: `/lol/items/${itemId}`,
        }}
      >
        <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
          {img}
        </TooltipContainer>
      </Link>
    );
  }

  return (
    <TooltipContainer tooltipInfo={tooltipInfo} disableTooltip={disableTooltip}>
      {img}
    </TooltipContainer>
  );
};

export default React.memo(Item);

// Item.whyDidYouRender = true;
