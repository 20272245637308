import { window, document } from "global";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pageview } from "lib/googleTagManagerHelper";

let SEND_PAGE_COUNT = 0;

const GoogleAnalyticsWrapper = (props) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    SEND_PAGE_COUNT += 1;
    // console.log("GAW SEND_PAGE_COUNT", SEND_PAGE_COUNT);
    // Don't refresh ads when redirecting for primary roles for champs:
    // e.g. sona/build?role=support -> sona/build
    let skip = false;
    if (history.action === "REPLACE") {
      if (location.pathname.endsWith("/build") && (location.search === "" || location.search === "?")) {
        skip = true;
      }
    }

    if (skip) {
      return;
    }

    if (window && window.dataLayer) {
      window.dataLayer.push({ event: "uggNewPage" });
    }
  }, [location.pathname.toLowerCase()]);

  useEffect(() => {
    if (history.action === "REPLACE") {
      if (location.pathname !== "/app") {
        return;
      }
    }

    if (location.state && location.state.validation === true) {
      return;
    } else {
      sendPageChange(location.pathname, location.search);
    }
  }, [location.pathname.toLowerCase(), location.search.substring(1).toLowerCase()]);

  return null;
};

let shortViewHandler;
let medViewHandler;
let longViewHandler;

function handleLongView() {
  // every 20 seconds on a page, send dataLayer event for long view
  clearTimeout(shortViewHandler);
  clearTimeout(medViewHandler);
  clearTimeout(longViewHandler);

  let suffix = "";
  // if (window.location.pathname === "/") {
  //   suffix = "-landing"
  // }

  shortViewHandler = setTimeout(function () {
    const eventName = "1-sec-view" + suffix;
    if (window && window.dataLayer) {
      window.dataLayer.push({ event: eventName });
    }
  }, 1000);

  medViewHandler = setTimeout(function () {
    const eventName = "10-sec-view" + suffix;
    if (window && window.dataLayer) {
      window.dataLayer.push({ event: eventName });
    }
  }, 10000);

  longViewHandler = setTimeout(function () {
    const eventName = "20-sec-view" + suffix;
    if (window && window.dataLayer) {
      window.dataLayer.push({ event: eventName });
    }
  }, 20000);
}

function sendPageChange(pathname, search = "") {
  handleEntrance();
  const page = pathname + search;
  pageview(page);
  handleLongView();
  if (window && window.rgea) {
    window.rgea("send");
  }
}

function handleEntrance() {
  // if this is the first time a user has come to u.gg, check which path they are
  // coming from
  if (window) {
    const entranceStorageName = "ugg_entrance";
    const shouldSkipEntrance = localStorage.getItem("shouldSkipEntrance");
    if (!shouldSkipEntrance) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "entrance",
          category: "entrance",
          value: "",
        });
      }
      localStorage.setItem(entranceStorageName, 1);
    }

    const timestamp = "ugg_entrance_timestamp";
    const shouldSkipTimestamp = localStorage.getItem(timestamp);
    if (!shouldSkipTimestamp) {
      localStorage.setItem(timestamp, Date.now());
    }
  }
}

export { GoogleAnalyticsWrapper, sendPageChange, SEND_PAGE_COUNT };
