import { window } from "global";
import { getCookie } from "@ugg/shared/utils/cookies";

export const DEFAULT_LANG = "en_US";

/**
 * This is uses the Riot API/Data Dragon naming schema for languages. Chrome has its
 * own language codes for some languages when you select them as your primary
 * language in settings. So they are included at the bottom of this array to
 * auto detect the language for users who have not been to the site before.
 */
export const uggSupportedLanguages = [
  "en_US", // English (US)
  "cs_CZ", // Czech
  "de_DE", // German
  "el_GR", // Greek
  "es_ES", // Spanish (Spain)
  "fr_FR", // French
  "hu_HU", // Hungarian
  "it_IT", // Italian
  "ja_JP", // Japanese
  "ko_KR", // Korean
  "pl_PL", // Polish
  "pt_BR", // Portuguese (Brazil)
  "ro_RO", // Romainian
  "ru_RU", // Russian
  "th_TH", // Thai
  "tr_TR", // Turkish
  "vn_VN", // ???? (Unkown but probably Vietnamese which is vi )
  "zh_CN", // Chinese (HongKong) (*Chrome says this is mandarin Chinese)
  "zh_TW", // Chinese(Taiwan)
];

export const LANGUAGE_OPTIONS = [
  { label: "English", value: "en_US" },
  { label: "Deutsch", value: "de_DE" },
  { label: "Español", value: "es_ES" },
  { label: "Français", value: "fr_FR" },
  { label: "Italiano", value: "it_IT" },
  { label: "Polski", value: "pl_PL" },
  { label: "Ελληνικά", value: "el_GR" },
  { label: "Română", value: "ro_RO" },
  { label: "Magyar", value: "hu_HU" },
  { label: "Čeština", value: "cs_CZ" },
  { label: "Português", value: "pt_BR" },
  { label: "Русский", value: "ru_RU" },
  { label: "Türkçe", value: "tr_TR" },
  { label: "Tiếng Việt", value: "vn_VN" },
  { label: "ไทย", value: "th_TH" },
  { label: "日本語", value: "ja_JP" },
  { label: "한국어", value: "ko_KR" },
  { label: "简体中文", value: "zh_CN" },
  { label: "繁體中文", value: "zh_TW" },
];

// Browser lang code -> Riot lang code
const langMapping = {
  vi: "vn_VN",
};

/**
 * Conversion function to convert between RIOT api language refrences and ISO standard languages for i18next translations.
 * ISO uses all lowercase and a "-" instead of "_" that RIOT uses.
 * @param {LANGUAGE_OPTIONS[n]} langToCheck
 * @returns ISO formatted language for a riot supported language.
 */
export function findRiotLangMatch(langToCheck) {
  const riotLangCode = langMapping[langToCheck];
  const normalizeLang = (riotLangCode || langToCheck).replace("-", "_").toLowerCase();

  //need to handle undefined case here.
  const matchLang = uggSupportedLanguages.find((el) => {
    return el.toLowerCase().indexOf(normalizeLang) === 0;
  });
  return matchLang;
}

/**
 * Gets the language when the language is requested via params.
 * If nothing is provided it would get the language that is provided by the broswer's cookie.
 * @returns the User's prefered language.
 */
export function getUserLanguage({ req, language } = {}) {
  let finalLang = DEFAULT_LANG;
  if (language) {
    const matchLang = findRiotLangMatch(language);
    if (matchLang) finalLang = matchLang;
  } else {
    let cookieLang = "",
      acceptedLanguages = [];
    if (window) {
      cookieLang = getCookie("ugg_lang");
      acceptedLanguages = window.navigator.languages;
    } else if (req) {
      cookieLang = req.cookies["ugg_lang"];
      acceptedLanguages = req.acceptsLanguages();
      let incomingPath = req.path;
      let tempLang = testPathForLang(incomingPath);
      if (tempLang !== undefined) {
        acceptedLanguages = [tempLang];
      }
    }
    const possibleLanguages = cookieLang ? [cookieLang, ...acceptedLanguages] : acceptedLanguages;

    const matchLang = findRiotLangMatch(possibleLanguages[0] || "");
    if (typeof cookieLang == "string" || typeof cookieLang == "undefined") {
      finalLang = matchLang;
    }
  }
  return finalLang;
}

export function testPathForLang(path) {
  for (let i = 0; i < uggSupportedLanguages.length; i++) {
    if (path.includes(uggSupportedLanguages[i].replace("-", "_").toLowerCase())) {
      return uggSupportedLanguages[i].replace("-", "_").toLowerCase();
    }
  }
}
