import React from "react";
import classnames from "classnames";
import PromoProgress from "./PromoProgress";
import { getS13RankUrl } from "@outplayed/riot-assets";
import { getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { mergeLPs } from "@ugg/shared/components/SummonerProfiles/overview/match-history/match-history-helpers";
import { ReactComponent as TriangleArrowUp } from "svg/triangle-arrow-up.svg";
import { ReactComponent as TriangleArrowDown } from "svg/triangle-arrow-down.svg";

const LPDiff = ({ lp, promotedTo }) => {
  if (promotedTo || isNaN(lp)) return null;
  return (
    <div className="lp-gain_loss">
      {lp > 0 ? (
        <TriangleArrowUp className={"lp-arrow gain"} />
      ) : lp < 0 ? (
        <TriangleArrowDown className={"lp-arrow loss"} />
      ) : (
        <span className="no-diff">&ndash;</span>
      )}
      <span className="lp-value">{`${Math.abs(lp)} LP`}</span>
    </div>
  );
};

const RankUp = ({ promotedTo, lp, promoProgress }) => {
  if (!promotedTo || (isNaN(lp) && !promoProgress)) return null;
  return (
    <div className="rank-up">
      <TriangleArrowUp className="lp-arrow" />
      <img className="promoted-tier-img" src={getS13RankUrl(promotedTo.tier, true)} />
      <div className="promoted-rank">{getProBuildRankName(promotedTo.tier, promotedTo.rank, true)}</div>
    </div>
  );
};

const MatchLPDisplay = (props) => {
  const { matchLP, serverLP } = props;
  const combinedLP = mergeLPs(matchLP, serverLP);

  const { lp, placement, promoProgress, promoTarget, promotedTo } = combinedLP || {};

  if ((isNaN(lp) || lp === null) && !promoProgress && (!placement || placement < 0)) {
    return <span>- ? LP</span>;
  }

  return (
    <div className="match-lp-display">
      <LPDiff lp={lp} promotedTo={promotedTo} />
      {promoProgress && <PromoProgress promoProgress={promoProgress} />}
      <RankUp lp={lp} promoProgress={promoProgress} promotedTo={promotedTo} />
    </div>
  );
};

export default MatchLPDisplay;
