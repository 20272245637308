export function parseQueryString(query = "") {
  return Object.fromEntries(new URLSearchParams(query));
}

export function buildQueryParams(params: Record<string | number, any>) {
  const urlParams = Object.entries(params).map(([key, value]) => [String(key), String(value)]);

  return new URLSearchParams(Object.fromEntries(urlParams)).toString();
  // if (!params) return "";

  // return Object.keys(params)
  //   .map((key) => {
  //     return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
  //   })
  //   .join("&");
}

export function replacePlusFromURLPath(string: string, replaceValue = " ") {
  if (!string) return string;
  return string.replace("+", replaceValue);
}
