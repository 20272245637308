import React from "react";
import { useUGGPatches } from "@ugg/shared/api/requests/patches";
import { useUGGApiVersions } from "@ugg/shared/api/requests/ugg-api-versions";

const AppDataInitializer = (props) => {
  const patches = useUGGPatches({ ssr: true });
  const versions = useUGGApiVersions({ ssr: true });

  const allDataFetched = [patches, versions].every((el) => el.data && !el.loading && !el.error);

  if (!allDataFetched) {
    return null;
  }

  return props.children;
};

export default AppDataInitializer;
