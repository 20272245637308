import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { useApolloClient, useQuery, useMutation } from "@apollo/client";
import {
  LOGIN,
  VERIFY_RIOT_CODE,
  GET_USER_SETTINGS,
  UPDATE_SETTINGS,
  VERIFY_EMAIL,
  REVERIFY_EMAIL,
} from "@ugg/shared/api/graphql/queries";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { addCookie, removeCookie } from "@ugg/shared/utils/cookies";

function setUserStateAnalytics(userState) {
  // ### Using the Google Analytics ID for user-id setup in ###
  // User is logged in
  if (window && window.dataLayer) {
    if (userState.googleAnalyticsId) {
      window.dataLayer.push({ userId: userState.googleAnalyticsId });

      if (userState.premium && userState.premium.isPremium) {
        window.dataLayer.push({ accountType: "boost" });
      } else {
        // User is free
        window.dataLayer.push({ accountType: "free" });
      }
    } else {
      window.dataLayer.push({ userId: null });
    }
  }
}

function clearAnalytics() {
  if (window && window.analytics) {
    window.analytics.reset();
  }
}

// Detect if user is logged in
export const useLoginState = () => {
  const [authToken] = useGlobal("authToken");
  const [loggingIn] = useGlobal("loggingIn");

  return !loggingIn && authToken ? true : false;
};

// Get user premium data
export const useUserPremiumState = () => {
  const { loading, data, error } = useQuery(GET_USER_STATE);
  if (loading || error) return {};

  return data.getState.premium;
};

export const useLogin = (creds, onCompleted, onError) => {
  const client = useApolloClient();
  const [authToken, setAuthToken] = useGlobal("authToken");
  const [loggingIn, setLoggingIn] = useGlobal("loggingIn");

  return () => {
    setLoggingIn(true);
    client
      .query({
        query: LOGIN,
        variables: creds,
        errorPolicy: "all",
        context: { clientName: "auth-api" },
      })
      .then((response) => {
        const { data, errors } = response;
        if (errors) {
          setLoggingIn(false);
          setAuthToken(null);
          removeCookie("authToken");
          onError && onError(errors[0].message);
        } else {
          const { login } = data;
          const { loginState } = login;
          const { token } = loginState;

          const isPremium = login.premium && login.premium.isPremium;
          if (isPremium) {
            localStorage.setItem("dontLoadGoogleCMP", true);
          }

          setUserStateAnalytics(login);
          if (window) {
            if (creds.persistLogin) {
              addCookie("authToken", token);
            } else {
              addCookie("authToken", token, true);
            }
            // sessionStorage.setItem("authToken", token);
            // if (creds.persistLogin) {
            //   localStorage.setItem("authToken", token);
            // }
            // window.location.reload();
            onCompleted && onCompleted(data);
          }
          // setAuthToken(token)
        }
        setLoggingIn(false);
      })
      .catch((error) => {
        setLoggingIn(false);
        setAuthToken(null);
        removeCookie("authToken");
        onError && onError(error);
      });
  };
};

export const useManualUserState = (options = {}) => {
  const client = useApolloClient();
  const [fetchState, setFetchState] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const getUserState = () => {
    setFetchState({ loading: true, data: null, error: null });
    client
      .query({
        query: GET_USER_STATE,
        ...options,
      })
      .then((response) => {
        setFetchState({ loading: false, data: response.data, error: null });
      })
      .catch((error) => {
        setFetchState({ loading: false, data: null, error });
      });
  };

  return [getUserState, fetchState];
};

export const useManualUserSettings = (options = {}) => {
  const client = useApolloClient();
  const [fetchState, setFetchState] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const getUserState = () => {
    setFetchState({ loading: true, data: null, error: null });
    client
      .query({
        query: GET_USER_SETTINGS,
        ...options,
      })
      .then((response) => {
        setFetchState({ loading: false, data: response.data, error: null });
      })
      .catch((error) => {
        setFetchState({ loading: false, data: null, error });
      });
  };

  return [getUserState, fetchState];
};

export const useVerifyEmail = (verificationCode, onCompleted, onError) => {
  const [verificationBar, setVerificationBar] = useGlobal("verificationBar");
  return useMutation(VERIFY_EMAIL, {
    variables: { verificationCode },
    refetchQueries: [{ query: GET_USER_STATE }],
    context: { clientName: "auth-api" },
    onCompleted,
    onError,
  });
};

// Resend verification email
export const useReverifyEmail = ({ variables, onCompleted, onError } = {}) => {
  const client = useApolloClient();
  return () => {
    client
      .query({
        query: REVERIFY_EMAIL,
        variables: typeof variables !== "object" ? {} : variables,
        context: { clientName: "auth-api" },
      })
      .then((response) => {
        const { data, errors } = response;

        if (errors) {
          onError && onError(errors);
        } else {
          if (data.reverifyEmail.success) {
            onCompleted && onCompleted(data);
          }
        }
      })
      .catch((error) => {
        onError && onError(error);
      });
  };
};

export const useRiotVerify = (options = {}) => {
  const client = useApolloClient();
  const [fetchState, setFetchState] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const verifyRiotAccount = () => {
    setFetchState({ loading: true, data: null, error: null });
    client
      .query({
        query: VERIFY_RIOT_CODE,
        ...options,
      })
      .then((response) => {
        options.onCompleted && options.onCompleted(response.data);
        setFetchState({ loading: false, data: response.data, error: null });
      })
      .catch((error) => {
        options.onError && options.onError(error);
        setFetchState({ loading: false, data: null, error });
      });
  };

  return [verifyRiotAccount, fetchState];
};

export const useFormatChampionFavorites = () => {
  const { getChampionImg, getChampionName, getNormalizedChampionName } = getRiotAssetsContext();
  return (favorites) =>
    (favorites || []).map((element) => {
      let stringChampionId = String(element.championId);
      return {
        championId: element.championId,
        championImage: getChampionImg(stringChampionId),
        championName: getChampionName(stringChampionId),
        normalizedChampionName: getNormalizedChampionName(stringChampionId),
      };
    });
};

const MIN_FAVORITES_DEBOUNCE = 2000;
let debounceFavorites = null;
export const useFavorites = () => {
  const [clientChampionFavorites, setClientChampionFavorites] = useGlobal("clientChampionFavorites");
  const [clientSummonerFavorites, setClientSummonerFavorites] = useGlobal("clientSummonerFavorites");
  const [updateFavorites, mutateResults] = useMutation(UPDATE_SETTINGS, {
    refetchQueries: [{ query: GET_USER_STATE }],
  });

  const formatChampionFavorites = useFormatChampionFavorites();

  const saveFavorites = (favoriteType, favorites) => {
    if (!favoriteType) {
      throw new Error("Require 'favoriteType'");
    }

    favoriteType === "champions" && setClientChampionFavorites(formatChampionFavorites(favorites));
    favoriteType === "summoners" && setClientSummonerFavorites(favorites);

    debounceFavorites && clearTimeout(debounceFavorites);
    debounceFavorites = setTimeout(() => {
      let cleanChampions = favoriteType === "champions" ? favorites : clientChampionFavorites || [],
        cleanSummoners = favoriteType === "summoners" ? favorites : clientSummonerFavorites || [];

      cleanChampions = cleanChampions.map((champion) => {
        return { championId: champion.championId };
      });

      cleanSummoners = cleanSummoners.map((summoner) => {
        return {
          riotUserName: summoner.riotUserName,
          riotTagLine: summoner.riotTagLine,
          iconUrl: summoner.iconUrl,
          regionId: summoner.regionId,
        };
      });

      updateFavorites({
        variables: {
          favoriteChampions: cleanChampions,
          favoriteSummoners: cleanSummoners,
        },
      });
    }, MIN_FAVORITES_DEBOUNCE);
  };

  return [saveFavorites, mutateResults];
};
