import { window } from "global";
import moment from "moment";

export function isValidEmail(email) {
  return email.match(/.+@(\b(?!-)[a-zA-z0-9-]+(?!-)\b)?\.([a-z]{2,})/g) !== null ? true : false;
}

export function isValidPassword(password) {
  return password.length >= 8 && password.match(/[a-zA-Z]+/g) && password.match(/[\d]+/g);
}

// all values should be integers
// return true if valid
// return error message if not valid
export function isValidBirthday(month, day, year) {
  if (!month || !day || !year) {
    return { error: "invalid" };
  }

  const momentDOB = moment(`${year}-${month}-${day}`, "YYYY-M-D");
  const isValidDate = momentDOB.isValid();
  if (!isValidDate) {
    return { error: "invalid" };
  } else {
    const age = moment().diff(momentDOB, "years");
    if (age < 13) {
      return { error: "underage" };
    }
  }

  return true;
}

// accepted format:
//   { value: 'some value' } or some primitive value
export function onInputChange(currentValues, type, value) {
  const currentInputState = currentValues[type];
  let newInputState;

  // NOTE: null is an object
  if (typeof currentInputState === "object") {
    newInputState = { ...currentInputState };
    newInputState.value = value;
  } else {
    newInputState = value;
  }

  const newFormState = { [type]: newInputState };
  return newFormState;
}

/**
 * @param {String} action - Some action key for google analysis
 * @param {function} onSuccess - Callback on success that gets passed the token
 * @param {function} onError - Callback on error
 */
export function grecaptchaExecute(action = "", onSuccess, onError) {
  if (window) {
    try {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(process.env.RECAPTCHA_SITEKEY_V3, { action }).then((token) => {
          onSuccess && onSuccess(token);
        });
      });
    } catch (error) {
      onError && onError(error);
    }
  }
}
