import window from "global";
import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { useTranslation } from "react-i18next";
import { getUserLanguage } from "lib/locale-helpers";
import { addCookie } from "@ugg/shared/utils/cookies";
import { DEFAULT_LANG } from "lib/locale-helpers";

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useGlobal("language");

  const _setLanguage = (_language: string) => {
    let finalLang = getUserLanguage({ req: null, language: _language }) || DEFAULT_LANG;

    if (window) {
      addCookie("ugg_lang", finalLang);
      i18n.on("languageChanged", (lng) => {
        document.documentElement.setAttribute("lang", lng.substring(0, 2).toLowerCase());
      });
    }

    i18n && i18n.changeLanguage(finalLang);

    setLanguage(finalLang);
  };

  return [language, _setLanguage] as const;
};
