import { window } from "global";
import React, { useCallback, useEffect } from "react";
import { useGlobal } from "reactn";
import { WINDOW_BREAKPOINTS_RANGE, DEFAULT_BREAKPOINT } from "lib/constants";

function getWindowBreakpoint(width) {
  if (!width) return DEFAULT_BREAKPOINT;

  for (let [breakpoint, breakpointWidth] of Object.entries(WINDOW_BREAKPOINTS_RANGE)) {
    if (width >= breakpointWidth[0] && width <= breakpointWidth[1]) {
      return breakpoint;
    }
  }
  return DEFAULT_BREAKPOINT;
}

const Responsive = (props) => {
  const [responsive, setResponsive] = useGlobal("responsive");

  const resizeHandler = useCallback(() => {
    const breakpoint = getWindowBreakpoint(window.innerWidth);
    if (breakpoint !== responsive) {
      setResponsive(breakpoint);
    }
  }, [responsive]);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", resizeHandler);

      return () => window.removeEventListener("resize", resizeHandler);
    }
  }, [resizeHandler]);
  // console.log(responsive)
  return null;
};

export default Responsive;
