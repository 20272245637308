import { buildQueryParams } from "@ugg/shared/utils/url-query-params";
import { OVERVIEW_DEFAULT } from "@ugg/shared/query-params/filter-options/champions/overview";
import { TIER_LIST_DEFAULT } from "@ugg/shared/query-params/filter-options/tier-lists/tier-list";

export function generateBuildAndRunes(filterDefaults) {
  let filterBuildsAndRunes = {};
  if (filterDefaults && filterDefaults.filterBuildsAndRunes) {
    if (filterDefaults.filterBuildsAndRunes.mmr && filterDefaults.filterBuildsAndRunes.mmr !== OVERVIEW_DEFAULT.rank) {
      filterBuildsAndRunes = Object.assign(filterBuildsAndRunes, { rank: filterDefaults.filterBuildsAndRunes.mmr });
    }

    if (
      filterDefaults.filterBuildsAndRunes.regionId &&
      filterDefaults.filterBuildsAndRunes.regionId !== OVERVIEW_DEFAULT.region
    ) {
      filterBuildsAndRunes = Object.assign(filterBuildsAndRunes, { region: filterDefaults.filterBuildsAndRunes.regionId });
    }

    if (
      filterDefaults.filterBuildsAndRunes.queueType &&
      filterDefaults.filterBuildsAndRunes.queueType !== OVERVIEW_DEFAULT.queueType
    ) {
      filterBuildsAndRunes = Object.assign(filterBuildsAndRunes, { queueType: filterDefaults.filterBuildsAndRunes.queueType });
    }
  }

  const filterParams = `?${buildQueryParams(filterBuildsAndRunes)}`;
  return filterParams;
}

export function generateTierList(filterDefaults) {
  let filterTierList = {};

  if (filterDefaults && filterDefaults.filterTierList) {
    if (filterDefaults.filterTierList.regionId && filterDefaults.filterTierList.regionId !== TIER_LIST_DEFAULT.region) {
      filterTierList = Object.assign(filterTierList, { region: filterDefaults.filterTierList.regionId });
    }

    if (filterDefaults.filterTierList.showAllChampions) {
      filterTierList = Object.assign(filterTierList, { allChampions: "true" });
    }

    if (filterDefaults.filterTierList.mmr && filterDefaults.filterTierList.mmr !== TIER_LIST_DEFAULT.rank) {
      filterTierList = Object.assign(filterTierList, { rank: filterDefaults.filterTierList.mmr });
    }

    if (filterDefaults.filterTierList.role && filterDefaults.filterTierList.role !== TIER_LIST_DEFAULT.role) {
      filterTierList = Object.assign(filterTierList, { role: filterDefaults.filterTierList.role });
    }

    if (filterDefaults.filterTierList.queueType && filterDefaults.filterTierList.queueType !== TIER_LIST_DEFAULT.queueType) {
      filterTierList = Object.assign(filterTierList, { queueType: filterDefaults.filterTierList.queueType });
    }

    const filterParams = `?${buildQueryParams(filterTierList)}`;
    return filterParams;
  }

  return "";
}

// Returns url tier list
export function convertToRoleTierList(filters) {
  const getRolePathname = () => {
    switch (filters.role) {
      case "top":
        return "/lol/top-lane-tier-list";
      case "jungle":
        return "/lol/jungle-tier-list";
      case "middle":
        return "/lol/mid-lane-tier-list";
      case "adc":
        return "/lol/adc-tier-list";
      case "support":
        return "/lol/support-tier-list";
      default:
        return "/lol/tier-list";
    }
  };

  const noRole = { ...filters };
  delete noRole.role;

  return {
    pathname: getRolePathname(),
    search: buildQueryParams(noRole),
  };
}
